.banner-home {
    position: relative;
    background: url('../../../../src/assets/images/home/bg.png');
    background-position-y: -88px;
    margin-bottom: 120px;
    @media (max-width: 1320px) {
        margin-bottom: 0;
        padding-bottom: 64px;
    }
    @media (max-width: 700px) {
        padding-bottom: 40px;
    }
    .container-banner {
        @media screen and (max-width: 991px) {
            background: #131518;
        }
        .box-content-banner {
            max-width: 1320px;
            @media (max-width: 1320px) {
                padding-left: 24px;
                padding-right: 24px;
                max-width: 100%;
                flex-wrap: wrap;
            }
            @media (max-width: 700px) {
                padding: 40px 16px;
                max-width: 100%;
            }

            margin: auto;
            display: flex;
            justify-content: space-between;
            padding: 64px 0;
            align-items: center;

            > div {
                width: 50%;
            }

            @media (max-width: 1200px) {
            }

            @media (max-width: 700px) {
            }

            .left-banner {
                flex-basis: calc(50% - 70px);
                @media (max-width: 1320px) {
                    flex-basis: 100%;
                }
                @media (max-width: 700px) {
                    max-width: 100%;
                    .row-list-btn {
                        button.btn-buy-swap,
                        button.btn-launch-dash {
                            width: 100%;
                            max-width: 100%;
                        }
                    }
                }

                .title-1 {
                    color: #ffffff;
                    font-weight: bold;

                    font-size: 48px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 140%;

                    .name-token {
                        color: #00a7ff;
                    }

                    @media (max-width: 700px) {
                        font-size: 24px;
                    }
                }

                .description-1 {
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 140%;
                    margin-bottom: 24px;
                    margin-top: 18px;
                }

                .row-list-btn {
                    display: flex;
                    gap: 12px;

                    .btn-launch {
                        width: fit-content;
                    }
                    @media (max-width: 700px) {
                        flex-direction: column;
                        .btn-launch {
                            width: 100%;
                        }
                    }
                }
                button {
                    padding: 16px 24px;
                    height: 49px;

                    font-weight: bold;
                    cursor: pointer;
                    font-size: 14px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    width: fit-content;
                }
            }

            .right-banner {
                flex-basis: calc(50%);

                @media (max-width: 1200px) {
                    flex-basis: 100%;
                    text-align: center;
                    margin-top: 77px;
                }
                @media (max-width: 700px) {
                    margin-top: 24px;
                }
                .iframe-view {
                    width: 100%;
                    height: 400px;
                }

                .style-banner-right {
                    width: 100%;

                    @media (max-width: 1200px) {
                        width: 50%;
                    }
                    @media (max-width: 991px) {
                        width: calc(100% - 64px);
                    }
                    @media (max-width: 700px) {
                        width: 100%;
                    }
                }
            }
        }
    }
}

.stats-info {
    max-width: 1320px;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
    @media (max-width: 1320px) {
        grid-template-columns: repeat(3, 1fr);
        padding-left: 24px;
        padding-right: 24px;
        max-width: 100%;
    }
    @media (max-width: 991px) {
        grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: 700px) {
        padding-left: 16px;
        padding-right: 16px;
        max-width: 100%;
        grid-template-columns: 100%;
        gap: 16px;
    }

    .item-info {
        gap: 24px;
        text-align: center;
        height: 116px;
        padding: 24px;
        width: 100%;
        align-items: center;

        border-radius: 12px;

        position: relative;

        box-sizing: border-box;

        border-radius: var(--Border-Radius-Large, 24px);
        background: var(--Text-Primary, #fff);

        .title-i {
            color: #000;
            font-size: 16px;
            margin-bottom: 12px;
        }

        .price-i {
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 140%;
            color: #00a7ff;
        }
        @media (max-width: 1320px) {
            background-position: center;
            margin: 0 auto;
        }
    }
}
