.menu-header {
    min-height: 76px;
    z-index: 100;
    position: relative;

    .main-menu {
        backdrop-filter: blur(8px);
        background-color: rgba(255, 255, 255, 0.05);
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        display: flex;
        align-items: center;

        height: 88px;

        @media (max-width: 1320px) {
            position: relative;
            padding: 0;
        }

        .container-menu {
            display: flex;
            justify-content: space-between;
            align-items: center;
            max-width: 1320px;
            .left-menu {
                .style-menu-logo {
                    display: none;
                }
                .btn-dashboard {
                    display: none;
                }
                .logo-mobile {
                    display: none;
                }
                @media (max-width: 1200px) {
                    .btn-dashboard {
                        display: flex;
                    }
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    > div:first-child {
                        display: flex;
                    }
                    .logo-desktop {
                        display: none;
                    }
                    .logo-mobile {
                        display: block;
                    }
                }
            }
            @media (max-width: 1320px) {
                padding-left: 24px;
                padding-right: 24px;
                max-width: 100%;
            }
            @media (max-width: 700px) {
                padding-left: 16px;
                padding-right: 16px;
                max-width: 100%;
            }

            margin: auto;
            width: 100%;
            @media (max-width: 1200px) {
                justify-content: flex-start;
                .right-menu {
                    order: 1;
                }
                .left-menu {
                    order: 2;
                    .style-menu-logo {
                        display: block;
                        margin-right: 12px;
                    }
                }
                .icon-colspan-menu-mobile {
                    margin-right: 12px;
                }
            }

            .left-menu {
                display: flex;
                align-items: center;
                // @media (max-width: 1200px) {
                //     margin-left: 16px;
                // }

                .style-logo {
                    height: 56px;
                }
                .title-menu {
                    color: #ffffff;
                    font-size: 28px;
                    line-height: 28px;
                    text-transform: uppercase;
                    margin-left: 10px;
                    font-weight: bold;
                }
            }
            .right-menu {
                display: flex;
                align-items: center;
                .overlay {
                    position: absolute;
                    width: calc(100vw);
                    top: 0;
                    right: 0;
                    background: #000;
                    opacity: 0.8;
                    height: 100vh;
                }
                .menu-list {
                    display: flex;
                    align-items: center;
                    margin-bottom: 0;
                    padding-left: 0;
                    // margin-right: 12px;
                    .style-logo {
                        height: 36px;
                    }
                    .header-mobile {
                        display: none;
                    }

                    ul {
                        margin-left: 0;
                        padding-left: 0;
                        margin-bottom: 0;
                    }
                    @media (max-width: 1200px) {
                        .header-mobile {
                            display: flex;
                            padding: 16px;
                            justify-content: space-between;
                        }
                        display: block;
                        transform: translateX(-314px);
                        position: absolute;
                        top: 0;
                        left: 0;
                        height: 100vh;
                        width: 314px;
                        z-index: 1000;
                        background: #202225;
                        backdrop-filter: blur(25px);
                        transition: 0.5s all;
                    }

                    .item-menu {
                        display: inline-block;
                        position: relative;
                        padding: var(--Border-Radius-Normal, 12px) 16px;

                        @media (max-width: 1200px) {
                            display: block;
                            &:not(:last-child) {
                                margin-bottom: 8px;
                            }
                        }

                        .soon-text {
                            // @media (max-width: 700px) {
                            //     right: 32% !important;
                            // }
                        }

                        .link-item {
                            color: #ffffff;
                            text-decoration: none;
                            font-size: 16px;
                            position: relative;
                            line-height: 140%;
                            font-weight: bold;
                            transition: 0.3s;
                            &:hover {
                                color: #00a7ff;
                            }
                            .soon-text {
                                top: 0;
                                @media (max-width: 1200px) {
                                    top: -20px;
                                }
                            }
                            &:last-child {
                                padding-right: 0;
                            }
                        }
                    }
                }

                .show-menu-mobile {
                    display: block;
                    transform: translateX(0);
                    margin-right: 0;
                    .btn-primary {
                        display: none;
                    }
                }
            }
        }
    }

    .fixed-menu {
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 1;
    }
}

.icon-colspan-menu-mobile {
    display: none;

    @media (max-width: 1200px) {
        display: block;
        // margin-right: 10px;
    }

    span {
        @media (max-width: 1200px) {
            color: #ffffff;
            font-size: 30px;
        }
    }
}
